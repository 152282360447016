// DELETE CONFIRMATION BUTTON
Vue.component('submit-form', {
    props: ['href', 'data', 'title', 'success', 'id'],
    template: '<button type="button" class="btn btn-primary" @click.stop.prevent="submit" href="#">' +
    '{{title}}</i>' +
    '</button>',

    ready: function () {
    },
    methods: {
        submit: function () {
            that = this;


            if (that.id) {
                swal({
                    title: this.title,
                    text: 'Sie können diesen Vorgang nicht rückgängig machen!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ja, Abschliessen!',
                    cancelButtonText: 'Nein',

                }).then(function (isConfirm) {
                    if (isConfirm === true) {
                        return $(that.id).submit();
                    }

                });
                return;
            }

            $.ajax({
                type: 'POST',
                url: that.href,
                data: $.extend({
                    _token: $('body').data('token')
                }, that.data),
                beforeSend: function () {
                },
                success: function (data) {
                    swal(
                        'Erfolg!',
                        that.success,
                        'success'
                    ).then(function () {
                        that.$dispatch('submit-form:submitted');
                    });
                },
                error: function () {
                    swal(
                        'Fehler!',
                        'Es ist ein Fehler aufgetreten!',
                        'error'
                    )
                }
            });


        }
    }
});
