// DELETE CONFIRMATION BUTTON
Vue.component('post-url', {
    props: ['href', 'title', 'ajax', 'data', 'method', 'success', 'text'],
    template: '<a @click.stop.prevent="delete" href="#" class="btn btn-xs btn-danger">' +
    '<i class="glyphicon glyphicon-trash"></i>' +
    '</a>',

    ready: function () {
    },
    methods: {
        ajax_submit: function () {

            that = this;
            $.ajax({
                type: (that.method ? that.method : 'DELETE'),
                url: that.href,
                data: $.extend({
                    _token: $('body').data('token')
                }, that.data),
                beforeSend: function () {
                },
                success: function (data) {
                    swal({
                        title: 'Erfolg!',
                        text: that.success,
                        type: 'success',
                        timer: 2000
                    }).then(function () {
                        that.$dispatch('delete-resource:ajax_submitted');
                    });
                },
                error: function () {
                    swal(
                        'Fehler!',
                        'Es ist ein Fehler aufgetreten!',
                        'error'
                    )
                }
            });
        },
        delete: function () {
            that = this;
            if (!that.text) {
                that.text = 'Sie können diesen Vorgang nicht rückgängig machen!';
            }
            swal({
                title: this.title,
                text: that.text,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'JA',
                cancelButtonText: 'Nein',
                closeOnConfirm: (that.ajax === true ? false : true),

            }).then(function (isConfirm) {
                if (isConfirm === true) {

                    if (that.ajax === true) {
                        swal.enableLoading();

                        return that.ajax_submit();
                    }
                    // DELETE REQUEST TO SERVER
                    var form =
                        $('<form>', {
                            'method': 'POST',
                            'action': that.href
                        });

                    var token =
                        $('<input>', {
                            'type': 'hidden',
                            'name': '_token',
                            'value': $('body').data('token')
                        });

                    // var hiddenInput =
                    //     $('<input>', {
                    //         'name': '_method',
                    //         'type': 'hidden',
                    //         'value': 'delete'
                    //     });

                    form.append(token)
                        .appendTo('body').submit();


                } else if (isConfirm === false) {
                    /*                    swal(
                     'Fehler!',
                     'Es ist ein Fehler aufgetreten!',
                     'error'
                     )*/
                } else {
                    // Esc key pressed or outside click,
                    // isConfirm is undefined
                }
            });
        }
    }
});
