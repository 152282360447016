// DELETE CONFIRMATION BUTTON
Vue.component('delete-resource', {
    props: ['href', 'title', 'ajax', 'data'],
    template: '<a @click.stop.prevent="delete" href="#" class="btn btn-xs btn-danger">' +
    '<i class="glyphicon glyphicon-trash"></i>' +
    '</a>',

    ready: function () {
    },
    methods: {
        ajax_submit: function () {

            if (that.data.participants) {
                that.data.participants = _.filter(that.data.participants, function (n) {
                    return n != undefined
                });
            }
            
            $.ajax({
                type: 'DELETE',
                url: that.href,
                data: $.extend({
                    _token: $('body').data('token')
                }, that.data),
                beforeSend: function () {
                },
                success: function (data) {
                    swal(
                        'Erfolg!',
                        '',
                        'success'
                    ).then(function () {
                        that.$dispatch('delete-resource:ajax_submitted');
                    });
                },
                error: function () {
                    swal(
                        'Fehler!',
                        'Es ist ein Fehler aufgetreten!',
                        'error'
                    )
                }
            });
        },
        delete: function () {
            that = this;
            swal({
                title: this.title,
                text: 'Sie können diesen Vorgang nicht rückgängig machen!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja, löschen!',
                cancelButtonText: 'Nein',

            }).then(function (isConfirm) {
                if (isConfirm === true) {

                    if (that.ajax === true) {
                        return that.ajax_submit();
                    }
                    // DELETE REQUEST TO SERVER
                    var form =
                        $('<form>', {
                            'method': 'POST',
                            'action': that.href
                        });

                    var token =
                        $('<input>', {
                            'type': 'hidden',
                            'name': '_token',
                            'value': $('body').data('token')
                        });

                    var hiddenInput =
                        $('<input>', {
                            'name': '_method',
                            'type': 'hidden',
                            'value': 'delete'
                        });

                    form.append(token, hiddenInput)
                        .appendTo('body').submit();


                } else if (isConfirm === false) {
                    /*                    swal(
                     'Fehler!',
                     'Es ist ein Fehler aufgetreten!',
                     'error'
                     )*/
                } else {
                    // Esc key pressed or outside click,
                    // isConfirm is undefined
                }
            });
        }
    }
});
