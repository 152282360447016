// DELETE CONFIRMATION BUTTON
Vue.component('open-route', {
    props: ['href', 'title'],
    template: '<a @click.stop.prevent="open" href="#">' +
    '{{title}}</i>' +
    '</a>',

    ready: function () {
    },
    methods: {
        open: function () {
            that = this;

            var form =
                $('<form>', {
                    'method': 'GET',
                    'action': that.href
                });

            var token =
                $('<input>', {
                    'type': 'hidden',
                    'name': '_token',
                    'value': $('body').data('token')
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': 'delete'
                });

            form.append(token, hiddenInput)
                .appendTo('body').submit();




        }
    }
});
