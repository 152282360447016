// confirmation
Vue.component('alert-user', {
    props: ['title', 'text', 'type'],
    template: '',
    ready: function () {
        swal(
            this.title,
            this.text,
            this.type
        );
    },
    methods: {}
});