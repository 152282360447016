Vue.component('select-participant', {
    props: {
        project_url: {
            type: String
        },
        units: {
            type: Array
        },
        participant_filter: {
            type: Array
        },
    },

    events: {
        'delete-resource:ajax_submitted': function () {
            this.selected = [];
            this.refetch_project();
        },
        'submit-form:submitted': function () {
            this.unitModal = false;
            this.groupModal = false;
            this.unit_selected = false;
            this.group_selected = {};

            // refetch participants
            this.selected = [];
            this.refetch_project();
        }
    },
    components: {
        dropdown: VueStrap.dropdown,
        modal: VueStrap.modal,
    },
    data: function () {
        return {
            groups: [],
            participants: [],
            participants_filtered: [],
            unit_selected: false,
            group_selected: {},
            project: {},
            selected: [],
            search: '',
            selected_all: true,
            unitModal: false,
            groupModal: false
        }
    },
    computed: {
        has_selections: function () {
            return _.find(this.selected, function (val) {
                return val;
            });
        },
        selected_filtered: function () {
            return _.filter(this.selected, function (p) {
                return (p);
            });
        }
    },
    watch: {},
    ready: function () {
        this.refetch_project();
        that = this;
    },
    methods: {
        concat_assignments: function (unit, groups) {
            data = [];

            if (unit) {
                data.push(unit.name);
            }

            if (groups) {
                groups.forEach(function (group) {
                    data.push(group.name);
                });
            }


            //console.log(data);
            return (data.length ? data.join(', ') : '-');
        },
        refetch_project: function () {
            that = this;
            $.ajax({
                type: 'GET',
                url: that.project_url,
                beforeSend: function () {
                    this.participants = [];
                    this.groups = [];
                },
                success: function (data) {
                    that.$set('project', data);
                    if (that.participant_filter) {
                        var part_filtered = _.filter(data.participants, function ($participant) {
                            return (_.find(that.participant_filter, function ($filter_id) {
                                return ($participant.id == $filter_id);
                            }));
                        });
                        that.$set('participants', part_filtered);
                    } else {
                        that.$set('participants', data.participants);
                    }
                    that.$set('groups', data.groups);
                },
                error: function () {
                }
            });
        },

        assign_organisation_units: function () {


            console.log('assign');
        },


        toggle: function (idx) {
            this.selected.$set(idx, !this.selected[idx]);
        },
        select_all: function () {
            that = this;
            this.participants_filtered.forEach(function (participant, idx) {
                that.selected.$set(participant.id, participant.id);
            });
        },
        select_none: function () {
            that.selected = [];
        },

        path: function (obj, path, def) {
            var i, len;

            for (i = 0, path = path.split('.'), len = path.length; i < len; i++) {
                if (!obj || typeof obj !== 'object') return def;
                obj = obj[path[i]];
            }

            if (obj === undefined) return def;
            return obj;
        }
    },
    filters: {
        searchForIn: function (data, value, keys) {
            keys = keys.split(/, ?/);

            var matches = [];

            data.forEach(function (obj) {
                keys.forEach(function (path) {
                    var propVal = this.path(obj, path);
                    if (propVal && propVal == value) {
                        matches.push(obj);
                    }
                }.bind(this));
            }.bind(this));

            return matches;
        },
        watchobjects: function (arr) {
            this.$set('participants_filtered', arr)
            return arr
        }
    }
});
