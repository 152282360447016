var md_map = new Vue({
    el: '#app-layout',

    props: {},
    data: {
        showRight: false,
        showTop: false,
        smallModal: false,
    },
    components: {
        dropdown: VueStrap.dropdown,
        modal: VueStrap.modal,
        tooltip: VueStrap.tooltip
    },
    computed: {},

    ready: function () {
    },
    methods: {}

});


var featured = Vue.extend({
    props: ['channel'],
    ready: function () {
    }
});