// confirmation
var assignCondition = Vue.component('assign-condition', {
    props: ['group_url', 'group_url_save'],
    template: '<a @click.stop.prevent="openModal" href="#" class="btn btn-xs">' +
    '<i class="glyphicon glyphicon-screenshot"></i>' +
    '</a>',
    ready: function () {
    },
    methods: {
        openModal: function () {
            this.$dispatch('participant-group-conditions:openModal', this.group_url, this.group_url_save);
        }
    }
});


// confirmation
Vue.component('participant-group-conditions', {
    components: {
        assignCondition: assignCondition,
        modal: VueStrap.modal,
        tooltip: VueStrap.tooltip,
    },
    events: {
        'submit-form:submitted': function () {
            this.assignConditionModal = false;
            this.fetch_groups();
        },
        'participant-group-conditions:openModal': function (group_url, group_url_save) {
            this.group_url_save = group_url_save;
            this.assignConditionModal = true;
            that = this;

            // fetch it
            $.ajax({
                type: 'GET',
                url: group_url,
                beforeSend: function () {
                    that.groups = [];
                    that.conditions = [];
                },
                success: function (data) {
                    that.$set('groups', data.groups);
                    that.$set('conditionals', data.conditionals.hidden);

                },
                error: function () {
                }
            });
        },
    },
    props: ['groups_url'],
    data: function () {
        return {
            assignConditionModal: false,
            groups: [],
            conditionals: [],
            group_url_save: '',
            groups_all: {},
        }
    },
    ready: function () {
        //fetch dem groupos
        this.fetch_groups();
    },
    methods: {
        fetch_groups: function () {
            that = this;
            $.ajax({
                type: 'GET',
                url: this.groups_url,
                beforeSend: function () {
                    that.groups_all = [];
                },
                success: function (data) {
                    that.$set('groups_all', data);
                },
                error: function () {
                }
            });

        }
    }
});


